<template>
  <BasePage
    :bannerUrl="indexBannerImg"
    subTilte="海衡招标火热进行中！"
    content="规范服务 / 阳光清廉 / 示范引领 / 降本增效"
  >
    <div
      style="
        margin: 30px auto;
        width: 80%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
      "
    >
      <TypeList />
      <NewsTable />
    </div>
  </BasePage>
</template>

<script>
import imgData from "@/data";
import { NewsTableStore } from "@/store";
import { reactive, computed, onBeforeMount } from "vue";
import BasePage from "@/components/BasePage/BasePage.vue";
import TypeList from "./components/typeList/typeList";
import NewsTable from "./components/NewsTable/NewsTable";
import { storeToRefs } from "pinia";
import { mainStore } from "@/store";

export default {
  components: { BasePage, TypeList, NewsTable },
  setup() {
    onBeforeMount(() => {
      mainStore().setData("news_ActiveType", "news");
    });
    const data = reactive({});
    return {
      ...imgData,
      ...data,
    };
  },
};
</script>

<style scoped>
</style>

