<template>
  <div style="width: 100%">
    <div class="newsTale_wrap" style="min-height: 800px">
      <!-- :style="tableTotal > 5 ? 'height: 1700px;' : 'height:800px'" -->

      <div
        class="newsTable_item"
        v-for="item in tableData"
        :key="item"
        @click="setData(item)"
        style="cursor: pointer"
      >
        <div class="newsTable_item_left">
          <div class="newsTable_item_left_title">{{ item.title }}</div>
          <div class="newsTable_item_left_content">{{ item.description }}</div>
          <div class="newsTable_item_left_bottom">
            发布时间：{{ item.createTime }}
          </div>
        </div>
        <div class="newsTable_item_rigth">
          <img
            :style="item.thumbnailUrlList.length > 0 ? '' : 'display:none'"
            :src="
              item.thumbnailUrlList.length > 0
                ? getUrl(item.thumbnailUrlList[0].name)
                : ''
            "
          />
          <!-- imgData.NewsCenterImg.policy.item1 -->
        </div>
      </div>
    </div>
    <div>
      <el-pagination
        style="justify-content: flex-end; width: 90%; margin: 20px 0"
        v-model:currentPage="currentPage"
        v-model:page-size="pageSize"
        :page-sizes="[10, 20, 50, 100]"
        :background="true"
        layout="total,prev, pager, next, sizes,jumper"
        :total="tableTotal"
        @size-change="ChangeSize"
        @current-change="ChangePage"
      />
    </div>
  </div>
</template>

<script>
import imgData from "@/data";
import { reactive, computed, onBeforeMount, ref } from "vue";
import {
  mainStore,
  stateStore,
  BiddingTableStore,
  NewsTableStore,
} from "@/store";
import { storeToRefs } from "pinia";
import _ from "lodash";
import { useRouter } from "vue-router";
import { getNewsTable } from "@/api/index";
export default {
  setup() {
    const store = mainStore();
    onBeforeMount(async () => {
      const res = await getNewsTable({
        current: store.news_currentPage || 1,
        size: store.news_size || 10,
        id: store.news_ActiveId || "123456",
      });
      store.setData("news_total", res.data.total);
      store.setData("news_data", res.data.records);
    });
    const router = useRouter();
    let tableData = computed(() => storeToRefs(store).news_data);
    const tableTotal = computed(() => storeToRefs(store).news_total);
    const ChangeSize = (value) => {
      store.setData("news_size", value);
      store.newsReloadTableData();
    };
    const ChangePage = (value) => {
      store.setData("news_currentPage", value);
      store.newsReloadTableData();
    };
    const setData = (item) => {
      stateStore().setactiveHtml(
        item.content,
        item.title,
        item.createTime,
        item
      );
      window.open(
        router.resolve({
          path: "/InfoView",
          query: { id: item.id,type:'新闻详情' },
        }).href,
        "_blank"
      );
    };
    const currentPage = computed(() => storeToRefs(store).currentPage);
    const getUrl = (name) => {
      return window.location.origin + "/" + name;
    };
    const data = reactive({
      currentPage,
      pageSize: 10,
      tableData,
      tableTotal,
      ChangeSize,
      ChangePage,
    });

    return {
      ...data,
      // loadData,
      imgData,
      setData,
      getUrl,
    };
  },
};
</script>

<style scoped>
@import url("./NewsTable.css");
</style>

